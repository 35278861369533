import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import React from "react";
import "./WelcomeComponent.css";
import PropTypes from "prop-types";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import GridViewIcon from "@mui/icons-material/GridView";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { useLayoutContext } from "../../providers/layout.provider";
import WorkIcon from "@mui/icons-material/Work";
import GradeIcon from "@mui/icons-material/Grade";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Groups3Icon from "@mui/icons-material/Groups3";
import SmsIcon from "@mui/icons-material/Sms";

export default function WelcomeSliderComponent() {
  const containerCSS =
    window.innerWidth < 800
      ? { display: "flex", margin: "0px", flexDirection: "column" }
      : { display: "flex", margin: "40px" };
  return (
    <div>
      <div style={containerCSS}>
        <WelcomeSliderTileComponent
          titleComponent={{
            url: "https://www.expensehut.com",
            text: "ExpenseHut",
          }}
          titleBackgroundColor={"var(--primary-light)"}
          subtitle={"Dashboard for personal finance."}
          listBody={[
            {
              avatar: {
                component: () => (
                  <AccountBalanceIcon
                    style={{ color: "crimson", fontSize: 30 }}
                  />
                ),
              },
              title: "Connect to Bank Accounts",
              subtitle:
                "All credit card transactions uploaded to one centralized dashboard.",
            },
            {
              avatar: {
                component: () => (
                  <SmsIcon style={{ color: "cornflowerblue", fontSize: 30 }} />
                ),
              },
              title: "SMS to check balance",
              subtitle: "Just text BAL to check your balance.",
            },
            {
              avatar: {
                altText: "clock",
                source: "clock.png",
              },
              title: "Intel Mode - Type Less!",
              subtitle: "Type an expense to add in as little as 5 secs.",
            },
            {
              avatar: {
                altText: "mobile",
                source: "mobile.png",
              },
              title: "Web and Mobile!",
              subtitle: "Available in Web, Android and iOS",
            },
          ]}
        />

        <WelcomeSliderTileComponent
          titleComponent={{
            url: "https://wallet.expensehut.com",
            text: "Wallet",
          }}
          titleBackgroundColor={"var(--fnx-light-red)"}
          subtitle={"Split bills & receipts with friends or family."}
          listBody={[
            {
              avatar: {
                altText: "creditcard",
                source: "credit-card.svg",
              },
              title: "Split bills!",
              subtitle:
                "Easy to track how much you owe or receive money from friends.",
            },
            {
              avatar: {
                component: () => (
                  <VpnKeyIcon
                    style={{ color: "cornflowerblue", fontSize: 30 }}
                  />
                ),
              },
              title: "One Identity!",
              subtitle: "Register just once & access all our apps.",
            },
          ]}
        />
      </div>

      <div style={containerCSS}>
        <WelcomeSliderTileComponent
          titleComponent={{
            url: "https://www.blogs.expensehut.com",
            text: "Blogs",
          }}
          titleBackgroundColor={"lightsalmon"}
          subtitle={"Write and publish your articles for free. No fees."}
          listBody={[
            {
              avatar: {
                component: () => (
                  <ElectricBoltIcon
                    style={{ color: "darkorange", fontSize: 30 }}
                  />
                ),
              },
              title: "Type, Save and Publish!",
              subtitle: "Knowledge sharing is free!",
            },
            {
              avatar: {
                component: () => <GridViewIcon />,
              },
              title: "Images & Videos",
              subtitle: "Add limited images and videos to your articles.",
            },
          ]}
        />

        <WelcomeSliderTileComponent
          titleComponent={{
            url: "https://recruit.expensehut.com",
            text: "Recruit",
          }}
          titleBackgroundColor={"cornflowerblue"}
          subtitle={"Next gen job portal!"}
          listBody={[
            {
              avatar: {
                component: () => (
                  <GradeIcon style={{ color: "orange", fontSize: 30 }} />
                ),
              },
              title: "Job Post Reviews",
              subtitle: "Save time - Read reviews before applying.",
            },
            {
              avatar: {
                component: () => (
                  <WorkIcon style={{ color: "dodgerblue", fontSize: 30 }} />
                ),
              },
              title: "Post Jobs",
              subtitle: "Post jobs and hire for free.",
            },
            {
              avatar: {
                component: () => (
                  <Groups3Icon
                    style={{ color: "lightseagreen", fontSize: 30 }}
                  />
                ),
              },
              title: "Referrals",
              subtitle: "Request referrals anonymously for your dream company.",
            },
          ]}
        />
      </div>
    </div>
  );
}

export function WelcomeSliderTileComponent({
  titleBackgroundColor,
  titleComponent,
  subtitle,
  listBody,
}) {
  const { isMobile } = useLayoutContext();

  return (
    <Slide
      direction="right"
      in={true}
      mountOnEnter
      unmountOnExit
      timeout={{
        appear: 1000,
        enter: 3000,
        // exit: 5000
      }}
    >
      <Paper
        elevation={3}
        sx={{
          margin: "20px 25px",
          height: "auto",
          width: 300,
        }}
      >
        <div style={{ padding: 15, display: "flex", flexDirection: "column" }}>
          <div
            style={{
              padding: 8,
              borderRadius: 5,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              fontSize: 25,
              backgroundColor: titleBackgroundColor,
            }}
          >
            <a
              target={"_blank"}
              href={titleComponent.url}
              style={{
                color: "white",
                textUnderlineOffset: 3,
                fontWeight: "bold",
              }}
              rel="noreferrer"
            >
              {titleComponent.text}
            </a>
          </div>

          <div
            style={{ fontSize: 20, textAlign: "center", margin: "20px 0px" }}
          >
            {subtitle}
          </div>

          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {listBody &&
              listBody.map((item, i) => {
                return (
                  <ListItem alignItems="flex-start" key={i}>
                    <ListItemAvatar style={{ overflow: "auto" }}>
                      {!item.avatar.component && (
                        <Avatar
                          alt={item.avatar.altText}
                          src={item.avatar.source}
                          style={{ overflow: "unset" }}
                        />
                      )}
                      {item.avatar.component && item.avatar.component()}
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.title}
                      secondary={
                        <React.Fragment>{item.subtitle}</React.Fragment>
                      }
                    />
                  </ListItem>
                );
              })}
          </List>
        </div>
      </Paper>
    </Slide>
  );
}

WelcomeSliderTileComponent.propTypes = {
  titleComponent: PropTypes.any,
  subtitle: PropTypes.string,
  listBody: PropTypes.array,
  titleBackgroundColor: PropTypes.string,
};
