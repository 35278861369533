import React from "react";
import { Link } from "react-router-dom";

export default class OurAppsComponent extends React.Component {
  render() {
    return (
      <div id="contents" style={{ width: "90%" }}>
        <table>
          <thead>
            <tr>
              <th style={{ width: "10%" }}></th>

              <th style={{ width: "30%" }}>Expense Hut</th>
              <th style={{ width: "30%" }}>Wallet</th>
              <th style={{ width: "30%" }}>Blogs</th>

              <th style={{ width: "30%" }}>FamilyNetworx (Retired)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Where do I find?</td>

              <td>
                <a
                  target="_blank"
                  href="https://app.expensehut.com"
                  rel="noreferrer"
                >
                  Sign Up
                </a>
              </td>
              <td>
                <a
                  target="_blank"
                  href="https://wallet.expensehut.com"
                  rel="noreferrer"
                >
                  Wallet
                </a>
              </td>

              <td>
                <a
                  target="_blank"
                  href="https://blogs.expensehut.com"
                  rel="noreferrer"
                >
                  Let&apos;s write
                </a>
              </td>

              <td>
                <Link to="/apps/fnxlegacy">More Info</Link>
              </td>
            </tr>

            <tr>
              <td> Authentication </td>

              <td>
                <a
                  target="_blank"
                  href="https://blogs.expensehut.com/articles/623e9ccfe5328361c95c9859/published"
                  rel="noreferrer"
                >
                  Expense Hut Identity
                </a>
              </td>
              <td>
                <a
                  target="_blank"
                  href="https://blogs.expensehut.com/articles/623e9ccfe5328361c95c9859/published"
                  rel="noreferrer"
                >
                  Expense Hut Identity
                </a>
              </td>
              <td>
                <a
                  target="_blank"
                  href="https://blogs.expensehut.com/articles/623e9ccfe5328361c95c9859/published"
                  rel="noreferrer"
                >
                  Expense Hut Identity
                </a>
              </td>
              <td>-</td>
            </tr>
            <tr>
              <td>Features</td>

              <td>
                <ul style={{ listStyle: "none" }}>
                  <li>Expense & Savings management</li>
                  <li>Live exchange rates for savings</li>
                  <li> Loan management </li>
                  <li> Receipt scanning. </li>
                  <li> Income tracker </li>
                  <li>Analytics & Comparison</li>
                  <li> Monthly Reports </li>
                  <li>Intelligent mode - mobile</li>
                  <li>Supports Android and iOS.</li>
                </ul>
              </td>
              <td>
                <ul style={{ listStyle: "none" }}>
                  <li>Split bills</li>
                  <li>Multiple Groups</li>
                </ul>
              </td>

              <td>
                <ul style={{ listStyle: "none" }}>
                  <li>Create and publish for free.</li>
                  <li>Maintain versions of your publications</li>
                </ul>
              </td>

              <td>
                <ul style={{ listStyle: "none" }}>
                  <li>Discussion Threads</li>
                  <li>Expenses</li>
                  <li>Group Reminders</li>
                  <li>Notes & Password vault</li>
                  <li> User management</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>Actively Developed?</td>

              <td>
                <img className="iconSize" src="checked.png" />
              </td>
              <td>
                <img className="iconSize" src="checked.png" />
              </td>
              <td>
                <img className="iconSize" src="checked.png" />
              </td>

              <td>
                <img className="iconSize" src="cancel.png" />
              </td>
            </tr>

            <tr>
              <td>Platforms</td>

              <td>Web, Android, iOS</td>
              <td>Web</td>
              <td>Web</td>
              <td>Web</td>
            </tr>

            <tr>
              <td>Technology</td>

              <td>Web: PERN stack, Mobile: React Native</td>
              <td>Web: PERN stack</td>
              <td>MERN stack.</td>
              <td>Monolithic - j2ee, servlets</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
