import React from "react";
import { Link } from "react-router-dom";
import ExpenseHutWidgetComponent from "./ExpenseHutWidgetComponent";
import "./WelcomeComponent.css";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import WelcomeSliderComponent from "./WelcomeSliderComponent";
import { useLayoutContext } from "../../providers/layout.provider";

export default function WelcomeComponent() {
  const { isMobile } = useLayoutContext();

  return (
    <div id="contents" style={{ display: "flex" }}>
      <div
        style={{
          padding: "10px 20px",
          fontFamily: "Source Sans Pro, sans-serif",
        }}
      >
        <div
          className={isMobile ? "divColumn line-30" : "divSpread"}
          style={{ gap: 20 }}
        >
          <div>
            <img
              src="sabarishnarain.jpg"
              style={{ width: 100, height: 100, borderRadius: 50 }}
            />
          </div>

          <div>
            <p>
              I’m a father, husband and a digital innovator with a passion for
              technology and its potential to drive positive change.
            </p>

            <p>
              As a co-founder of ExpenseHut, my vision is to create more than
              just another app. ExpenseHut is a platform that hosts a suite of
              carefully designed software applications, each built to solve
              real-world problems and simplify the way people manage their daily
              lives.
            </p>
          </div>
        </div>

        <WelcomeSliderComponent />

        <Divider sx={{ marginBottom: "45px" }} />
      </div>

      <div
        style={{
          minWidth: "20%",
          background:
            "linear-gradient(90deg, rgba(0,107,136,1) 25%, rgba(38,0,51,1) 100%)",
          padding: "20px",
          borderRadius: "10px",
          display: window.innerWidth < 800 ? "none" : "block",
        }}
      >
        <ExpenseHutWidgetComponent />
      </div>
    </div>
  );
}
